import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from './locales/en'
import zhCNLocale from './locales/zh'
import tcCNLocale from './locales/tc'

Vue.use(VueI18n)

const messages = {
    en: {
        ...enLocale
    },
    zh: {
        ...zhCNLocale
    },
    tc: {
        ...tcCNLocale
    }
    // 你可以添加更多语言
}

// 设置默认语言
const lang = (navigator.language || navigator.userLanguage).toLowerCase()
const locale = lang.includes('zh') ? 'zh' : 'en'

export default new VueI18n({
    locale, // 设置默认语言
    messages, // 加载翻译文件
    silentTranslationWarn: true // 在生产环境中关闭翻译警告
})