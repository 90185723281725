import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
Vue.config.productionTip = false
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/main.css'
import router from './router' // 引入上面创建的路由配置
Vue.use(ElementUI);

// 注册组件


new Vue({
  i18n,
  router,
  el: '#app',
  render: h => h(App),
}).$mount('#app')
