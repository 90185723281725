import Vue from 'vue'
import Router from 'vue-router'


// 使用 vue-router
Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            component: () => import("../yemian/zh/index.vue")
        },
        {
            path: '/zhuce/en',
            component: () => import("../yemian/zh/zhuce.vue")
        },
        {
            path: '/dl/en',
            component: () => import("../yemian/zh/dl.vue")
        },
        {
            path: '/wj/en',
            component: () => import("../yemian/zh/wj.vue")
        },
        {
            path: '/gaofeng/en',
            component: () => import("../yemian/zh/gaofeng.vue")
        },
        {
            path: '/tk/en',
            component: () => import("../yemian/zh/tuikuani.vue")
        },
        {
            path: '/sj/en',
            component: () => import("../yemian/zh/shangjia.vue")
        },
        {
            path: '/jm/en',
            component: () => import("../yemian/zh/jiami.vue")
        },
        {
            path: '/zhip/en',
            component: () => import("../yemian/zh/zhipiao.vue")
        },
        {
            path: '/zfwg/en',
            component: () => import("../yemian/zh/zhifuwg.vue")
        },
        {
            path: '/gj/en',
            component: () => import("../yemian/zh/guoji.vue")
        },
        {
            path: '/gj2/en',
            component: () => import("../yemian/zh/guoji2.vue")
        },
        {
            path: '/hhr/en',
            component: () => import("../yemian/zh/hehuoren.vue")
        },
        {
            path: '/gy/en',
            component: () => import("../yemian/zh/gyu.vue")
        },



        {
            path: '/zh',
            component: () => import("../yemian/en/index.vue")
        },
        {
            path: '/zhuce/zh',
            component: () => import("../yemian/en/zhuce.vue")
        },
        {
            path: '/dl/zh',
            component: () => import("../yemian/en/dl.vue")
        },
        {
            path: '/wj/zh',
            component: () => import("../yemian/en/wj.vue")
        },
        {
            path: '/gaofeng/zh',
            component: () => import("../yemian/en/gaofeng.vue")
        },
        {
            path: '/tk/zh',
            component: () => import("../yemian/en/tuikuani.vue")
        },
        {
            path: '/sj/zh',
            component: () => import("../yemian/en/shangjia.vue")
        },
        {
            path: '/jm/zh',
            component: () => import("../yemian/en/jiami.vue")
        },
        {
            path: '/zhip/zh',
            component: () => import("../yemian/en/zhipiao.vue")
        },
        {
            path: '/zfwg/zh',
            component: () => import("../yemian/en/zhifuwg.vue")
        },
        {
            path: '/gj/zh',
            component: () => import("../yemian/en/guoji.vue")
        },
        {
            path: '/gj2/zh',
            component: () => import("../yemian/en/guoji2.vue")
        },
        {
            path: '/hhr/zh',
            component: () => import("../yemian/en/hehuoren.vue")
        },
        {
            path: '/gy/zh',
            component: () => import("../yemian/en/gyu.vue")
        },
        {
            path: '/new/zh',
            component: () => import("../yemian/en/new.vue")
        },
    ]
});
router.afterEach(() => {
    window.scrollTo(0, 0);
});
export default router;